import React from 'react'

export default function About() {
    return (
        <div className="about--section">
            <div className="main--section">
                <h4 className="about--title">About</h4>
                <p>
                    I am a frontend developer with a particular interest in making things simple and automating daily tasks. I try to keep up with security and best practices, and am always looking for new things to learn.
                </p>
            </div>
        </div>
    )
}
